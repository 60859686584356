import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export function OfferHistoryChart({dataSet}) {

  let data = dataSet.sort((a, b) => {
    const dateA = new Date(a.searchDate);
    const dateB = new Date(b.searchDate);
    return dateA.getTime() - dateB.getTime();
  });

  const chartData = {
    labels: data.map(item => {
      const today = new Date()
      const searchDate = new Date(item.searchDate)
      return Math.round((today.getTime() - searchDate.getTime()) / (1000 * 60 * 60)) + "h ago"
    }),
    datasets: [
      {
        label: 'Price',
        data: data.map(item => item.price),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.1
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend

      },
      tooltip: {
        callbacks: {
          // Custom tooltip
          label: function (context) {
            const {price, departureDate, returnDate} = data[context.dataIndex];
            return [
              `Price: ${price.toFixed(2)}`,
              `Departure: ${departureDate}`,
              `Return: ${returnDate}`,
            ];
          },
        },
      },
    },
  };

  return <Line options={options} data={chartData}/>;
}


